<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
// import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';

export default {
    name: 'AppCoinMerchant',
    components: {
        // TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: ''
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.requestPending = true;
                this.pagination.current = query.page || 1;
                this.getMerchants();
                this.requestPending = false;
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.news && this.news.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: this.pagination.current,
                    limit: this.pagination.perPage
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.items = response.data.result;
                    this.pagination = response.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                    this.checkCreateMore();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = {
                        method: '',
                        params: this.dataForModal
                    };

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.method = 'coin.merchant_update';
                            body.params.id = this.updatingItem.id;
                            await this.updateMerchant(body, config);
                            await this.getMerchants();
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            body.method = 'coin.merchant_create';
                            await this.createMerchant(body, config);
                            await this.getMerchants();
                            this.$toast.success(this.$t('success.added'));
                        }
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async createMerchant(body) {
            await api.coin
                .createCoinMerchant(body)
                .then(({ data }) => {
                    this.items.push(data);
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateMerchant(body, config) {
            await api.coin
                .updateCoinMerchant(body, config)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        // async deleteMerchant(id, index) {
        //   try {
        //     await api.news.delete(id)
        //     this.news.splice(index, 1)
        //
        //   } catch (e) {
        //     this.$toast.error(e.message)
        //   }
        // },
        // :delete-handler="deleteMerchant"

        changeAppID(appID) {
            this.activeAppID = appID;
            this.getMerchants();
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getMerchants();
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('navigation.coin_merchant') }}</template>
            <!--            <template v-if="hasAccess('merchants', 'create')" #button>-->
            <!--                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">-->
            <!--                    {{ $t('button.add') }}-->
            <!--                    <feather-icon icon="PlusIcon" />-->
            <!--                </b-button>-->
            <!--            </template>-->
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                class="news__table"
                striped
                outlined
                borderless
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>
                <template #cell(name)="{ item }">
                    <div style="min-width: 200px">
                        {{ item.name }}
                    </div>
                </template>
                <!--                <template #cell(actions)="{ item, index }">-->
                <!--                    <table-action-btns-->
                <!--                        :delete-access="{ section: 'merchants', permission: 'delete' }"-->
                <!--                        :index="index"-->
                <!--                        :item="item"-->
                <!--                        :update-access="{ section: 'merchants', permission: 'update' }"-->
                <!--                        @openModal="openModalToUpdate"-->
                <!--                    />-->
                <!--                </template>-->
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="updatingItem ? $t('edit.coin_merchant') : $t('add.coin_merchant')"
            cancel-variant="danger"
            centered
            size="sm"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="`${$t('enter.title')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
